import React, { Component } from "react"
import styled from "styled-components"

import CARDIMG1 from "../../images/card1.png"
import CARDIMG2 from "../../images/card2.png"
import CARDIMG3 from "../../images/card3.png"
import { Link } from "gatsby"

class HomepageThreeCard extends Component {
  render() {
    return (
      <>
        <MainBox>
          <Box>
            <SmallLogo>
              <img src={CARDIMG1} />
            </SmallLogo>
            <Content>
              <Link to="/outoftheboxuse/" style={{ color: "#4a4d57" }}>
                <h6>A Holistic Sales Platform without the headache!</h6>
                &nbsp;&nbsp;
              </Link>
              <p>
                A platform without the complexities of a Sales CRM. Hit the
                ground running in just a few minutes. Register/On-board, set up
                your sales processes, fill them up with opportunities, assign
                them to your reps and voila! you’re all set. Yep, we’ve made it
                that easy!!
              </p>
            </Content>
          </Box>

          <Box>
            <SmallLogo>
              <img src={CARDIMG2} />
            </SmallLogo>
            <Content>
              <Link to="/customerjourney/" style={{ color: "#4a4d57" }}>
                <h6>Spend More time winning, less time managing</h6>{" "}
                &nbsp;&nbsp;
              </Link>

              <p>
                Move faster with Pre-configured processses, KPIs and Dashboards.
                Bring your entire business in. All your opportunies tasks,
                catalogues, playbooks and more for your team to access in one
                place. Empower your team to sell more, sell fast with ease.
              </p>
            </Content>
          </Box>
          <Box>
            {" "}
            <SmallLogo>
              <img src={CARDIMG3} />
            </SmallLogo>
            <Content>
              <Link to="/automation/" style={{ color: "#4a4d57" }}>
                <h6>See and measure what’s working in real-time</h6>{" "}
                &nbsp;&nbsp;
              </Link>
              <p>
                Stay on top of your business with real-time insights on
                organization and team performances. Bring in non-sales users,
                manage tasks with opportunity lifecycle for faster execution.
                Manage escalation and approvals for faster turnarounds and more
                wins.
              </p>
            </Content>
          </Box>
        </MainBox>
      </>
    )
  }
}

export default HomepageThreeCard

export const SmallLogo = styled.div`
  text-align: left;
  padding: 3rem 2rem 0px;
  @media screen and (max-width: 600px) {
    text-align: center;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    text-align: center;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    text-align: center;
  }
`
export const MainBox = styled.div`
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin-left: 100px;
    margin-right: 100px;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    margin-left: 1px;
    margin-right: 37px;
  }
`
export const Box = styled.div`
  background-color: white;
  width: 347px;
  margin: 1.2rem;
  box-shadow: 5px 10px 18px grey;
  transition: 0.2s all linear;
  &:hover {
    transform: scale(1.02);
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
`
export const Content = styled.div`
  padding: 2rem;
  h6 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 1.375em;
    line-height: 30px;

    text-align: left;
    letter-spacing: -0.5px;

    color: #333333;
  }
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    font-size: 1.125em;
    line-height: 24px;
    text-align: left;
    color: #6b6a71;
  }
  @media screen and (max-width: 992px) {
    font-weight: 300;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    h6 {
      text-align: center;
    }
    p {
      text-align: center;
      font-weight: 300;
    }
  }
`
