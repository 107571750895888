import React, { Component } from "react"
import HOMEPAGE from "../../images/homepagenew.png"
import styled from "styled-components"
import { loginUrl } from "../../config/keys"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
class HomePageLandingScreen extends Component {
  render() {
    return (
      <>
        <Box>
          <BoxLeft>
            <BoxLeftMainHeader>
              Accelerate and Simplify your B2B Sales
            </BoxLeftMainHeader>
            <br />
            <BoxLeftContent>
              {" "}
              Sell more, work faster, engage better, close more opportunities,
              do more, do it all and take your B2B sales to new heights.
            </BoxLeftContent>

            <Button
              onClick={(e) => {
                // To stop the page reloading
                // e.preventDefault()
                // Lets track that custom click
                trackCustomEvent({
                  // string - required - The object that was interacted with (e.g.video)
                  category: "start your free trial  button in navbar ",
                  // string - required - Type of interaction (e.g. 'play')
                  action: "Click",
                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                  label: "Start your free trial button in navbar",
                  // number - optional - Numeric value associated with the event. (e.g. A product ID)
                  value: 43,
                })
                //... Other logic here
              }}
              href={`${loginUrl}/register/Option`}
              target="_blank"
            >
              Start Free Trial
            </Button>
          </BoxLeft>
          <BoxRight>
            <img src={HOMEPAGE} alt="landingscreenimage" width="100%" />
          </BoxRight>
        </Box>
        <Spacer />
      </>
    )
  }
}
export default HomePageLandingScreen

const Box = styled.div`
  display: flex;
  width: 100%;

  margin-top: 85px;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  }
`
const BoxLeft = styled.div`
  width: 47%;

  padding: 0px 40px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 10px 20px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: 100%;
    padding: 10px 20px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    padding: 10px 20px;
  }
`

const BoxLeftMainHeader = styled.h2`
  font-family: "Roboto";
  font-size: 3.1em;
  font-weight: 500;
  // text-align: left;
  margin: 0;
  letter-spacing: -0.5px;
  color: #333333;
  @media screen and (max-width: 600px) {
    font-size: 3rem;
  }
`
const BoxLeftContent = styled.p`
  font-family: "Roboto";
  font-size: 1.125em;
  text-align: left;
  font-weight: 100;
  color: #6b6a71;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`

const Button = styled.a`
  /* display: inline-block; */
  text-decoration: none !important;
  font-family: "Roboto";
  padding: 11px 20px;
  margin-top: 5px;
  background: #ee6352;
  border-radius: 4px;
  font-style: normal;

  text-align: center;
  font-weight: 100;
  font-size: 1.075em;
  align-self: flex-start;

  color: #ffffff !important;
  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 1em;
    text-align: center;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    width: 50%;
  }
`

const BoxRight = styled.div`
  width: 47%;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: 100%;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: flex;
    align-item: center;
    justify-content: center;
    width: 55%;
  }
`
const Spacer = styled.div`
  height: 20px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`
