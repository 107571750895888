import React, { Component } from "react"
import styled from "styled-components"
import HomePageBlueBox from "./HomePageBlueBox"
import HomePageLandingScreen from "./HomePageLandingScreen"
import HomepageThreeCard from "./HomePageThreeCard"
import HomePageSideBySideImageandText from "./HomePageSideBySideImageandText"
import HomePagePeachBox from "./HomePagePeachBox"

class Homepage extends Component {
  render() {
    return (
      <>
        <HomePageLandingScreen />
        <HomePageBlueBox />
        <Spacer />
        <Spacer />

        <Heading>Scale your Sales in Minutes</Heading>
        <SubHeading>
          Try our pre-configured sales automation platform to sell more with
          less manual work
        </SubHeading>
        <HomepageThreeCard />
        <Spacer />

        <HomePageSideBySideImageandText />

        <HomePagePeachBox />
      </>
    )
  }
}

export default Homepage
const Heading = styled.h1`
  font-family: "Roboto";
  font-weight: 100;
  font-style: normal;
  font-size: 2.5em;
  text-align: center;
  color: #333333;
  @media screen and (max-width: 600px) {
    padding: 0px 30px;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* flex-direction: column; */
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  }
`

const SubHeading = styled.h6`
  font-family: "Roboto";
  font-size: 1.375em;
  text-align: center;
  color: #333333;
  padding: 10px;
  font-weight: 100;
  @media screen and (max-width: 600px) {
    padding: 0px 30px;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* flex-direction: column; */
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  }
`

const Spacer = styled.div`
  height: 40px;
`
