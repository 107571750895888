import React, { Component } from "react"
import OUTOFBOX from "../../images/outofbox1.png"
import COLLAB from "../../images/collaboration1.png"
import { Link } from "gatsby"
import CUSTOMJ from "../../images/custj1.png"
import AUTOM from "../../images/automation1.png"
import LEADS from "../../images/leads1.png"
import SERVICE from "../../images/securityandservice.png"
import styled from "styled-components"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
class HomePageSideBySideImageandText extends Component {
  render() {
    return (
      <MainBox>
        <MainBoxHeader>Features</MainBoxHeader>
        {/* Out Of The Box */}
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img src={OUTOFBOX} alt="outofbox" width="100%" height="100%" />
            </ImageBox>
          </BoxRightMobile>
          <BoxLeft>
            <BoxLeftSubHeader>OUT OF THE BOX </BoxLeftSubHeader>
            <BoxLeftMainHeader>Get Set, Ready, Sell</BoxLeftMainHeader>
            <BoxLeftContent>
              {" "}
              Swing into action with pre-configured processes, built-in
              analytical tools and a wide range of extensive features and create
              immediate results. Empower your teams to sell faster, manage
              opportunities better, and increase win rates.
            </BoxLeftContent>

            <BoxLeftButton>
              <Link to="/outoftheboxuse/" style={{ color: "#ee6352" }}>
                {" "}
                Learn More &nbsp;
                <i className="fa fa-arrow-right" style={{ size: "7px" }}></i>
              </Link>
            </BoxLeftButton>
          </BoxLeft>
          <BoxRight>
            <ImageBox>
              <img src={OUTOFBOX} alt="outofbox" width="100%" height="100%" />
            </ImageBox>
          </BoxRight>
        </Box>
        <Spacer />
        {/* CustomerJourney */}

        <Box>
          <BoxRightMobile>
            <img src={CUSTOMJ} alt="customer" width="100%" />
          </BoxRightMobile>
          <BoxRight>
            <img src={CUSTOMJ} alt="customer" width="100%" />
          </BoxRight>
          <BoxLeft>
            <BoxLeftSubHeader>CUSTOMER JOURNEY MAPPING</BoxLeftSubHeader>
            <BoxLeftMainHeader>
              From new customers to customers for life
            </BoxLeftMainHeader>
            <BoxLeftContent>
              {" "}
              Remove friction out of your functions. Deepen customer
              relationships, increase wallet share, upsell & cross-sell.
              Understand your customers and pipeline in-depth with rich Pareto
              charts. Track Lifetime Value, Revenue contributions and more
              key-metrics in one place for your top customers, sell more and
              take your revenue to new heights.
            </BoxLeftContent>

            <BoxLeftButton>
              <Link to="/customerjourney/" style={{ color: "#ee6352" }}>
                {" "}
                Learn More &nbsp;
                <i className="fa fa-arrow-right" style={{ size: "7px" }}></i>
              </Link>
            </BoxLeftButton>
          </BoxLeft>
        </Box>
        <Spacer />
        {/* Automation */}
        <Box>
          <BoxRightMobile>
            <img src={AUTOM} alt="automation" width="100%" />
          </BoxRightMobile>
          <BoxLeft>
            <BoxLeftSubHeader>AUTOMATION</BoxLeftSubHeader>
            <BoxLeftMainHeader>
              Eliminate busy-ness out of your business
            </BoxLeftMainHeader>
            <BoxLeftContent>
              {" "}
              Eliminate repetitive tasks by automating your Sales Value chain.
              Capture leads and route them to your reps Automatically. Add and
              assign tasks to have total control over all your opportunities.
              Automate sending your quotations, follow-ups, reminders, also
              manage approvals and escalations with easy to use configurable
              processes, workflows.
            </BoxLeftContent>

            <BoxLeftButton>
              <Link to="/automation/" style={{ color: "#ee6352" }}>
                {" "}
                Learn More &nbsp;
                <i className="fa fa-arrow-right" style={{ size: "7px" }}></i>
              </Link>
            </BoxLeftButton>
          </BoxLeft>
          <BoxRight>
            <img src={AUTOM} alt="automation" width="100%" />
          </BoxRight>
        </Box>
        <Spacer />

        {/* Collaboration */}
        <Box>
          <BoxRightMobile>
            <img src={COLLAB} alt="collaboration" width="100%" />
          </BoxRightMobile>
          <BoxRight>
            <img src={COLLAB} alt="collaboration" width="100%" />
          </BoxRight>
          <BoxLeft>
            <BoxLeftSubHeader>COLLABORATION</BoxLeftSubHeader>
            <BoxLeftMainHeader>
              Connect your entire business in one.
            </BoxLeftMainHeader>
            <BoxLeftContent>
              {" "}
              Close more opportunities faster, bring in users from different
              non-sales functions to help complete tasks associated with
              opportunities. Enhance team productivity, collaborate with teams
              and alliances without any hassle.
            </BoxLeftContent>

            <BoxLeftButton>
              <Link
                to="/collaboratebreakthesilos/"
                style={{ color: "#ee6352" }}
              >
                {" "}
                Learn More &nbsp;
                <i className="fa fa-arrow-right" style={{ size: "7px" }}></i>
              </Link>
            </BoxLeftButton>
          </BoxLeft>
        </Box>
        <Spacer />
        {/* Leads to closure */}
        <Box>
          <BoxRightMobile>
            <img src={LEADS} alt="leads" width="100%" />
          </BoxRightMobile>
          <BoxLeft>
            <BoxLeftSubHeader>LEADS TO CLOSURE</BoxLeftSubHeader>
            <BoxLeftMainHeader>
              Gain Complete visiblity over your business
            </BoxLeftMainHeader>
            <BoxLeftContent>
              {" "}
              Track and monitor every interaction with customers, turn data into
              valuable nuggets of information to help you analyze and optimize
              for better customer engagement. Take control and make informed
              decisions with dashboards and perspective views to move towards
              your business goals.
            </BoxLeftContent>

            <BoxLeftButton>
              <Link
                to="/leadsopportunitiesandclosures/"
                style={{ color: "#ee6352" }}
              >
                {" "}
                Learn More &nbsp;
                <i className="fa fa-arrow-right" style={{ size: "7px" }}></i>
              </Link>
            </BoxLeftButton>
          </BoxLeft>
          <BoxRight>
            <img src={LEADS} alt="leads" width="100%" />
          </BoxRight>
        </Box>
        <Spacer />
        {/* Service */}
        <Box>
          <BoxRightMobile>
            <img src={SERVICE} alt="securityandservice" width="100%" />
          </BoxRightMobile>
          <BoxRight>
            <img src={SERVICE} alt="securityandservice" width="100%" />
          </BoxRight>
          <BoxLeft>
            <BoxLeftSubHeader>SECURITY & ACCESS</BoxLeftSubHeader>
            <BoxLeftMainHeader>Secure from the core</BoxLeftMainHeader>
            <BoxLeftContent>
              {" "}
              Designed for omni-channel access for your convenience. We’ve
              employed latest data security standards for your data safety and
              security. Have complete control and peace of mind over your data
              security all in one place.
            </BoxLeftContent>
          </BoxLeft>
        </Box>
        <Spacer />
      </MainBox>
    )
  }
}
export default HomePageSideBySideImageandText

const MainBox = styled.div`
  padding: 40px;
  background: #eafaff;
  @media screen and (max-width: 600px) {
    padding: 20px;
    overflow-x: hidden;
  }
  @media only screen and (min-width: 1200px) and (orientation: portrait) {
    overflow-x: hidden;
  }
  @media only screen and (min-width: 1200px) and (orientation: landscape) {
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    overflow-x: hidden;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  }
`
const MainBoxHeader = styled.p`
  font-family: "Roboto";
  font-weight: 100;
  margin-bottom: 0px;
  padding: 0px 20px;
  font-size: 1.125em;
  text-align: left;
  color: #000000;
  @media screen and (max-width: 600px) {
    display: none;
    font-weight: 300;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`

const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  /* @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
    padding: 0px;
  } */

  /* margin-top: 20px; */
`
const BoxLeft = styled.div`
  width: 47%;
  margin: 20px 0px;
  padding: 0px 20px;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 0px 5px;
  }
`
const BoxLeftSubHeader = styled.p`
  font-family: "Roboto";
  font-size: 1.125em;
  text-align: left;
  color: #2a1e5c;
  font-weight: 600;
  margin-bottom: 0.188em;
  @media screen and (max-width: 600px) {
    font-weight: 600;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 600;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 600;
  }
`
const BoxLeftMainHeader = styled.h2`
  font-family: "Roboto";
  font-size: 2.5em;
  font-weight: 500;
  text-align: left;
  margin: 0 auto;
  letter-spacing: -0.5px;
  color: #333333;
  @media screen and (max-width: 600px) {
    font-size: 34px;
  }
`
const BoxLeftContent = styled.p`
  font-family: " Roboto";
  font-size: 1.125 em;
  font-weight: 100;
  text-align: left;
  color: #6b6a71;
  @media screen and (max-width: 600px) {
    margin-top: 20px;
    font-weight: 300;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
const BoxLeftButton = styled.button`
  font-family: "Roboto";
  font-size: 1.125em;
  text-align: left;
  border: none;
  color: #ee6352;
  background: #eafaff;
`
const TrialLink1 = styled.a`
  color: #ee6352;
  @media screen and (max-width: 600px) {
    text-align: center;
    width: 100%;
  }
`
const BoxRight = styled.div`
  width: 42%;
  padding: 0px 30px;
  @media screen and (max-width: 600px) {
    width: 100%;
    display: none;
  }
`
const Spacer = styled.div`
  background-color: #eafaff;
  height: 50px;
`
const BoxRightMobile = styled.div`
  /* @media only screen and (min-width: 1200px) {
    display: none;
  } */
  @media only screen and (min-width: 1200px) {
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: block;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: none;
  }
`
const ImageBox = styled.div`
  /* border: 11px solid rgb(221, 221, 221);
  border-radius: 7px;

  background-color: whitesmoke; */
  overflow-x: hidden;
  @media screen and (max-width: 600px) {
    height: 220px;
    margin: 20px 10px;
    overflow-x: hidden;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 320px;
    margin: 20px 10px;
    overflow-x: hidden;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    height: 420px;
    margin: 20px 10px;
  }
`
