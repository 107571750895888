import React from "react"
import Layout from "../components/layout"
// import SEO from "../components/seo"
import { SEO, useSEO } from "gatsby-plugin-seo"
import Homepage from "../modules/Home/HomePage"
import scrollTo from "gatsby-plugin-smoothscroll"

const IndexPage = () => {
  const { siteUrl } = useSEO()
  return (
    <Layout>
      <SEO
        title="CRM & Sales Automation | Korero | Home  "
        description="Your platform for customer management, communication
        & collaboration. Out-of-the box, cloud based, SaaS solution using data driven approach
        ensures higher win rates. Simple UI & automation increases productivity."
        pagePath="/"
        schema={`{
        "@context": "http://schema.org",
        "@type": "WebPage",
        "mainEntity": {
          "@type": "Organization",
          "name": "Korero",
          "image": "${siteUrl}/src/image/Korero_logo1.png"
        }
      }`}
        keywords={[`gatsby`, `application`, `react`]}
      />
      <script src="https://platform.linkedin.com/in.js" type="text/javascript">
        {" "}
        lang: en_US
      </script>
      {/* <button onClick={() => scrollTo("#some-id")}>My link</button> */}
      <Homepage />
    </Layout>
  )
}

export default IndexPage
