import React, { Component } from "react"
import styled from "styled-components"
import { loginUrl } from "../../config/keys"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
// import BLUEIMAGE from "../../images/homepage/blueBox.jpg"
import OUTOFBOXTHREEFIRST from "../../images/OutOfbox/first.jpg"
class HomePageBlueBox extends Component {
  render() {
    return (
      <Box>
        <LeftBox>
          <ImageBox>
            <img
              src={OUTOFBOXTHREEFIRST}
              alt="one"
              width="100%"
              height="100%"
              // style="vertical-align:middle"
            />
          </ImageBox>
        </LeftBox>
        <RightBox>
          <RightBoxHeader>
            Engineered to run your entire Sales on one Platform
          </RightBoxHeader>
          <RightBoxContent>
            A holistic sales automation platform to help you unify Sales,
            operations, partners that support your growth. Empower your sales,
            to do more of what they love from day 1, with a platform without the
            clunkiness they’ll love to use.
          </RightBoxContent>

          <Button
            onClick={(e) => {
              // To stop the page reloading
              // e.preventDefault()
              // Lets track that custom click
              trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "start your free trial  button in navbar ",
                // string - required - Type of interaction (e.g. 'play')
                action: "Click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "Start your free trial button in navbar",
                // number - optional - Numeric value associated with the event. (e.g. A product ID)
                value: 43,
              })
              //... Other logic here
            }}
            href={`${loginUrl}/register/Option`}
            target="_blank"
          >
            Get Started
          </Button>
        </RightBox>
      </Box>
    )
  }
}
export default HomePageBlueBox

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em 2em;
  padding: 10px;
  background: #2a1e5c;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    grid-template-columns: 1fr;
    grid-gap: 0em;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  }
`
const LeftBox = styled.div`
  margin: 30px;

  display: grid;
  justify-content: center;
  align-content: center;
  @media screen and (max-width: 600px) {
    margin: 10px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin: 30px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  }
`
const RightBox = styled.div`
  margin: 30px;

  @media screen and (max-width: 600px) {
    margin: 10px;
  }
`
const RightBoxHeader = styled.div`
  font-family: "Roboto";
  font-weight: 100;
  font-size: 2.2em;
  letter-spacing: -0.031em;
  color: #ffffff;
  line-height: 1.2em;
  @media screen and (max-width: 600px) {
    line-height: 40px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    line-height: 45px;
  }
`
const RightBoxContent = styled.p`
  font-family: "Roboto";
  font-size: 1.125em;
  letter-spacing: -0.5px;
  text-align: left;
  color: #ffffff;
  margin-top: 30px;
  font-weight: 100;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
const Button = styled.a`
  /* display: inline-block; */
  text-decoration: none !important;
  font-family: "Roboto";
  padding: 11px 20px;
  margin-top: 10px;
  background: #ee6352;
  border-radius: 4px;
  font-style: normal;
  /* width: 30%; */
  font-weight: 100;
  text-align: center;

  font-size: 1.075em;

  color: #ffffff !important;
  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 1em;
    text-align: center;
        display: flex;
    justify-content: center;
}


  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    width: 50%;
  }
`
const ImageBox = styled.div`
  border: 5px solid rgb(221, 221, 221);
  border-radius: 7px;
  /* height: 480px; */
  background-color: whitesmoke;
  overflow-x: hidden;
  @media screen and (max-width: 600px) {
    height: 220px;
    margin: 20px 10px;
  }
`
